<template>
  <el-form
      class="lb-m-0 lb_top w-100"
      label-position="top"
      label-width="140px"
  >
    <div class="row">
      <div class="col-md-4">
        <el-form-item
            :label="$t('time_entry.spent_on')"
            :size="fieldsSize"
            class="mb-1"
            required
            v-permission="['pm-set-logged-date']"
        >
          <el-date-picker
              :class="checkinputRequired('spent_on') ? 'validate-error' : ''"
              :pickerOptions="{ firstDayOfWeek: 1 }"
              :size="fieldsSize"
              format="yyyy-MM-dd"
              name="spent_on"
              placeholder=""
              style="width: 100%;"
              type="date"
              v-model="form.spent_on"
          >
          </el-date-picker>
          <small
              class="help-block"
              v-if="backend_errors.spent_on && !checkinputRequired('spent_on')"
          >
            <span v-html="validationTranslate(backend_errors.spent_on[0])"></span>
          </small>
        </el-form-item>
      </div>
      <div class="col-md-4">
        <el-form-item
            :label="$t('time_entry.hours')"
            :size="fieldsSize"
            class="mb-1"
            required
        >
          <el-input
              :class="checkinputRequired('hours') ? 'validate-error' : ''"
              :size="fieldsSize"
              name="hours"
              placeholder="000:00"
              type="text"
              v-mask="'###:##'"
              v-model="form.hours"
          />
          <small
              class="help-block"
              v-if="backend_errors.hours && !checkinputRequired('hours')"
          >
            <span v-html="validationTranslate(backend_errors.hours[0])"></span>
          </small>
        </el-form-item>
      </div>
      <div class="col-4">
        <el-form-item
            :label="$t('time_entry.activity')"
            :size="fieldsSize"
            class="mb-1"
            required
            v-module="['activity']"
        >
          <el-select
              :class="checkinputRequired('activity_id') ? 'validate-error' : ''"
              :placeholder="$t('time_entry.activity')"
              :size="fieldsSize"
              filterable
              name="activity_id"
              style="width: 100%;"
              v-model="form.activity_id"
          >
            <el-option
                :key="value.id"
                :label="value.name"
                :value="value.id"
                v-for="value in activities"
            >
            </el-option>
          </el-select>
          <small
              class="help-block"
              v-if="backend_errors.activity_id && !checkinputRequired('activity_id')"
          >
            <span v-html="validationTranslate(backend_errors.activity_id[0])"></span>
          </small>
        </el-form-item>
      </div>
      <div class="col-12">
        <el-form-item
            :label="$t('time_entry.comments')"
            :size="fieldsSize"
            class="mb-1"
            required
        >
          <el-input
              :class="checkinputRequired('comments') ? 'validate-error' : ''"
              :size="fieldsSize"
              @keyup.ctrl.enter.native="save"
              name="comments"
              resize="none"
              rows="2"
              type="textarea"
              v-model="form.comments"
          >
          </el-input>
          <small
              class="help-block"
              v-if="backend_errors.comments && !checkinputRequired('comments')"
          >
            <span v-html="validationTranslate(backend_errors.comments[0])"></span>
          </small>
        </el-form-item>
      </div>
      <div class="col-12">
        <el-form-item class="m-0 ptx-20 d-flex justify-content-end">
          <el-button
              :loading="loading"
              :size="fieldsSize"
              @click="save"
              type="primary"
          >
            {{ $t('system.save') }}
          </el-button>
          <el-button
              :size="fieldsSize"
              @click="$emit('cancel')"
          >
            {{ $t('system.cancel') }}
          </el-button>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import {HHHmmToHHmmss} from '../../../../../helpers/time';

export default {
  name: 'timeLogForm',
  props: [
    'activities',
    'saveURL',
    'id',
  ],
  data() {
    return {
      fieldsSize: 'mini',
      form: {
        spent_on: new Date(),
      },
      loading: false,
      backend_errors: {},
      inputRequiredEmpty: [],
    };
  },
  mounted() {
    if (Array.isArray(this.activities) && this.activities.length) {
      const defaultValue = this.activities.find(item => item.is_default === 1);
      if (defaultValue) {
        Vue.set(this.form, 'activity_id', defaultValue.id);
      }
    }
  },
  methods: {
    save() {
      const requiredInputs = [];
      const formClone = {...this.form};
      formClone.hours = HHHmmToHHmmss(formClone.hours);
      if (formClone.hours === '00:00:00') {
        requiredInputs.push('hours');
      }
      this.checkValidation(requiredInputs).then(() => {
        formClone.spent_on = moment(this.form.spent_on).format('YYYY-MM-DD');
        // TODO przerobić na Backu "issue_id" -> "id"
        formClone.issue_id = this.id;
        formClone.hours = HHHmmToHHmmss(formClone.hours);
        this.loading = true;
        axios.post(this.saveURL, formClone)
            .then((response) => {
              this.$emit('saved', response);
            })
            .catch((error) => {
              this.loading = false;
              this.$emit('error', error);
              this.backend_errors = error.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
      });
    },
  },
};
</script>

<style scoped>

</style>
